import axios from 'axios';
import config from './config';
// import qs from 'qs';
import { Message } from '@wenjuan/ui';
import { remove as removeStorage } from '@/assets/js/utils/storage.js';
// import { HTTP_STATUS_CODE, HTTP_CLIENT_ERROR } from './httpErrorCode';
// import { get as getStorage, remove as delStorage } from '@/assets/js/utils/storage.js';
// import router from '../router';
const service = axios.create(config);
if (process.env.NODE_ENV == 'development') {
    //开发环境使用proxy的/api
    service.defaults.baseURL = '/api';
} else {
    service.defaults.baseURL = process.env.VUE_APP_BASE_URL;
}
// // 请求拦截器
// service.interceptors.request.use(
//     (config) => {
//         config.headers['Authorization'] = getStorage('spss_token');
//         console.log('post----', config);
//         // POST 传参序列化
//         if (config.method === 'post') {
//             if (config.data && config.data.__FORMDATA__) {
//                 config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
//                 delete config.data.__FORMDATA__;
//             }
//             if (config.headers['Content-Type'] == 'application/x-www-form-urlencoded') {
//                 config.data = qs.stringify(config.data);
//             }
//         }
//         return config;
//     },
//     (error) => {
//         return Promise.reject(error);
//     }
// );
// 响应拦截器
service.interceptors.response.use(
    (response) => {
        // let data = response.data;
        // // 处理业务逻辑错误
        // if (data && data.status_code && data.status_code === 0) {
        //     data.message =
        //         HTTP_CLIENT_ERROR[data.err_code] || data.msg || data.err_msg || data.err_code;
        //     Message({
        //         message: `${data.message}`,
        //         duration: 2000,
        //         offset: 70,
        //     });
        // }
        return response;
    },
    (error) => {
        if (
            error.response.data.code == 'Request.Unauthorized' &&
            error.response.data.message === '请先登录'
        ) {
            console.log('请先登录');
            removeStorage('isLogin');
            error.message = '请先登录';
            setTimeout(() => {
                window.location.href = '/';
            }, 800);
            Message({
                message: `${error.message}`,
                duration: 2000,
                type: 'error',
            });
        } else if (error.response.config.url.indexOf('user/me') == -1) {
            Message({
                message: `${error.response.data.message}`,
                duration: 2000,
                type: 'error',
            });
        }
        // if (
        //     (error.response.status == 401 && error.response.data.message === 'Unauthorized') ||
        //     error.response.data.message == '没有权限'
        // ) {
        //     error.message = '请先登录';
        //     if (getStorage('spss_token')) {
        //         delStorage('spss_token');
        //     }
        //     setTimeout(() => {
        //         window.location.href = '/';
        //     }, 800);
        // } else if (error && error.response && error.response.data) {
        //     error.message = error.response.data.message;
        // } else if (error && error.response) {
        //     error.message = HTTP_STATUS_CODE[error.response.status];
        // } else {
        //     error.message = HTTP_STATUS_CODE.default;
        // }
        // error.message = error.message || HTTP_STATUS_CODE.default;
        // // return Promise.resolve(error.response || error);

        return Promise.reject(error.response || error);
    }
);

// [注]put2中的data是body参数
export default {
    get: (url, data) => service({ method: 'get', url, params: data }),
    post: (url, data, params, headers) => service({ method: 'post', url, data, params, headers }),
    delete: (url, data) => service({ method: 'delete', url, data }),
    put: (url, data) => service({ method: 'put', url, params: data }),
    put2: (url, data) => service({ method: 'put', url, data }),
    patch: (url, data, params, headers) => service({ method: 'patch', url, data, params, headers }),
};
