import router from '../router';
const adminRoute = {
    name: 'admin',
    path: '/admin',
    component: () => import('@/layout/index.vue'),
    children: [
        {
            path: 'course',
            name: 'course',
            component: () => import('@/pages/course/index.vue'),
        },
        {
            path: 'catalog',
            component: () => import('@/pages/course/catalog.vue'),
        },
    ],
};
//用户管理路由
const userRoute = {
    name: 'user',
    path: '/user',
    component: () => import('@/layout/index.vue'),
    redirect: '/user/index',
    children: [{ path: 'index', component: () => import('@/pages/user/index.vue') }],
};
export default {
    SET_USERINFO(state, val) {
        state.userInfo = val;
    },
    SET_USERTYPE(state, val) {
        state.userType = val;
    },
    SET_ROUTERS(state) {
        if (state.userType == 'ADMIN') {
            router.addRoute(adminRoute);
            router.addRoute(userRoute);
        }
        router.addRoute({
            path: '*',
            name: '404',
            component: () => import('@/pages/404.vue'),
        });

        state.isAddRoutes = true;
    },
};
