import request from '../index.js';
// 用户登陆
export function httpPostLogin(params) {
    return request.post('/api/v1/signIn', params);
}
// 用户退出登陆
export function httpDeleteUserLogout() {
    return request.delete('/api/v1/signOut');
}

// 获取个人信息
export function httpGetUserInfo() {
    return request.get(`/api/v1/user/me`);
}

// 用户获取课堂列表
export function httpGetCourseList(params) {
    return request.get('/api/v1/course/page', params);
}
//获取课程详情
export function httpGetCourseInfo(params) {
    return request.post(`/api/v1/course/getCourseInfo`, params);
}
// 用户获取课程集列表
export function httpGetCourseEpisodeList(courseId) {
    return request.get(`/api/v1/course/${courseId}/episodes`);
}
// 用户获取课程集
export function httpGetCourseEpisodeDetail(episodeId) {
    return request.get(`/api/v1/course/episode/${episodeId}`);
}
// 用户获取考试
export function httpPostExam(episodeId, params) {
    return request.post(`/api/v1/educatee/current/course/episode/${episodeId}/exam`, params);
}

// 获取学校（用户）列表
export function httpGetEducateeUserList(params) {
    return request.get(`/api/v1/admin/educatee/page`, params);
}
// 创建学校用户
export function httpPostEducateeUser(params) {
    return request.post(`/api/v1/admin/educatee`, params);
}
//编辑学校用户
export function httpPutEditUser(educateeId, params) {
    return request.put2(`/api/v1/admin/educatee/${educateeId}`, params);
}
//获取视频播放凭证
export function httpGetPlayAuth(videoId) {
    return request.get(`/api/v1/video/${videoId}/play-auth`);
}
//修改用户启用禁用状态
export function httpPatchEnabled(params) {
    let { educateeId, enabled } = params;
    return request.patch(`/api/v1/admin/educatee/${educateeId}/enabled`, { enabled });
}
//删除用户
export function httpDeleteUser(educateeId) {
    return request.delete(`/api/v1/admin/educatee/${educateeId}`);
}
