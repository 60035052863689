import { httpGetUserInfo } from '@/http/user/index.js';
import { remove as removeStorage } from '@/assets/js/utils/storage.js';
export default {
    getUserInfo({ commit }) {
        return new Promise((resolve) => {
            httpGetUserInfo()
                .then((res) => {
                    let data = res.data.data;
                    commit('SET_USERINFO', data.detail);
                    commit('SET_USERTYPE', data.userType);
                    commit('SET_ROUTERS');
                    resolve();
                })
                .catch(() => {
                    removeStorage('isLogin');
                    resolve();
                });
        }).catch(() => {});
    },
};
