<template>
    <div id="app">
        <router-view />
    </div>
</template>
<script>
export default {
    created() {},
};
</script>
<style lang="scss" scoped>
#app {
    height: 100%;
}
</style>
