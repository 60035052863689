import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store/index.js';
import { get as getStorage } from '@/assets/js/utils/storage';
Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('@/pages/index.vue'),
        beforeEnter: (to, from, next) => {
            if (
                navigator.userAgent.match(
                    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
                )
            ) {
                next('/m');
            } else {
                next();
            }
        },
    },
    {
        path: '/m',
        name: 'home-m',
        component: () => import('@/pages/home/index-m.vue'),
    },
    {
        name: 'course',
        path: '/course',
        redirect: '/course/list',
        component: () => import('@/layout/index.vue'),
        children: [
            {
                path: 'list',
                name: 'list',
                component: () => import('@/pages/course/index.vue'),
            },
            {
                path: 'catalog',
                component: () => import('@/pages/course/catalog.vue'),
            },
            {
                path: 'manage',
                component: () => import('@/pages/course/manage.vue'),
            },
            {
                path: '/course/:courseId',
                component: () => import('@/pages/course/detail.vue'),
            },
        ],
    },
    {
        path: '/terms',
        name: 'terms',
        component: () => import('@/pages/clause/terms.vue'),
    },
    {
        path: '/privacy',
        name: 'privacy',
        component: () => import('@/pages/clause/privacy.vue'),
    },
];

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0, behavior: 'smooth' };
        }
    },
});
router.beforeEach((to, from, next) => {
    if (!store.state.userInfo.id && getStorage('isLogin')) {
        store
            .dispatch('getUserInfo')
            .then(() => {
                next({ ...to, replace: true });
            })
            .catch(() => {
                next({ ...to, replace: true });
            });
    } else {
        if (!store.state.isAddRoutes) {
            store.commit('SET_ROUTERS');
            next({ ...to, replace: true });
        } else {
            next();
        }
    }
});
export default router;
