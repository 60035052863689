import Vue from 'vue';
import Svg from '@/components/svg/index.vue';
Vue.component('svg-icon', Svg);

// /**
//  * require.context 的参数说明
//  * './svg' 代表要查找的文件路径
//  * false 代表是否查找子目录
//  * /\.svg$/ 代表要匹配文件的正则
//  *
//  */
// const requireAll = (requireContext) => requireContext.keys().map(requireContext);
// const req = require.context('./svg', false, /\.svg$/);
// // https://zhuanlan.zhihu.com/p/59564277
// requireAll(req);
