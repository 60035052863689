export default {
    // 自定义的请求头
    headers: {
        post: {
            'Content-Type': 'application/json;charset=UTF-8',
        },
        'X-Requested-With': 'XMLHttpRequest',
        'Access-Control-Allow-Origin': '*',
    },
    // 超时设置
    timeout: 40000,
    // 跨域是否带Token
    withCredentials: false,
    // 响应的数据格式 json / blob /document /arraybuffer / text / stream
    responseType: 'json',
};
