import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import '@/assets/js/utils/global.js';
import './assets/css/theme-wenjuan.scss';
import '@/assets/css/index.scss';
import './assets/iconfont/iconfont.css';
import './assets/iconsvg';
import 'github-markdown-css';
Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');
