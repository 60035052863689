import Vue from 'vue';
import { Message, MessageBox, Loading } from '@wenjuan/ui';
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$message = Message;
Vue.use(Loading.directive);
let loadingInstance = null;
Vue.prototype.showLoading = function () {
    loadingInstance = Loading.service({
        lock: true,
        text: '',
        spinner: 'wj-icon-loading-rota',
        background: 'rgba(255, 255, 255, 0.7)',
    });
};
Vue.prototype.hideLoading = function () {
    loadingInstance.close();
};
