<template>
    <span class="svg-icon" v-html="icon"></span>
</template>

<script>
export default {
    name: 'SvgIcon',
    props: {
        iconClass: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            icon: this.getIcon(),
        };
    },
    watch: {
        iconClass() {
            this.icon = this.getIcon();
            console.log('svg--', this.icon);
        },
    },
    methods: {
        getIcon() {
            console.log('svg', this.iconClass);
            return require(`@/assets/iconsvg/svg/${this.iconClass}.svg`);
        },
    },
};
</script>

<style lang="scss">
@mixin width-height-100 {
    width: 1.5em;
    height: 1.5em;
}
.svg-icon {
    @include width-height-100;
    display: inline-flex;
    & > svg {
        width: 1.5em !important;
        height: 1.5em !important;
    }
}
</style>
